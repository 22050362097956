import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import DatePicker from "react-datepicker"
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import Products from './components/products.js'

import 'react-datepicker/dist/react-datepicker.css'

const getDays = () => {
    return [
        {value: 1, name: 'Lundi'},
        {value: 2, name: 'Mardi'},
        {value: 3, name: 'Mercredi'},
        {value: 4, name: 'Jeudi'},
        {value: 5, name: 'Vendredi'},
        {value: 6, name: 'Samedi'},
        {value: 7, name: 'Dimanche'}
    ]
}

const getHourSlots = () => {
    const res = []
    for (let index = 8; index < 20; index++) {
        const seconds = index * 3600
        const secondsHalf = seconds + 1800
        res.push({value: seconds, name: (index < 10 ? '0' : '') + index + ':00'})
        res.push({value: secondsHalf, name: (index < 10 ? '0' : '') + index + ':30'})
    }
    return res
}

const getDurations = () => {
    return [
        {value: 900, name: '15 min'},
        {value: 1800, name: '30 min'},
        {value: 2700, name: '45 min'},
        {value: 3600, name: '1 h'}
    ]
}

const getRests = () => {
    return [
        {value: 0, name: 'Aucune'},
        {value: 300, name: '5 min'},
        {value: 600, name: '10 min'},
        {value: 900, name: '15 min'}
    ]
}

const SelectDay = ({value, handleChange}) => {
    const options = getDays().map((option, index) => {
        if(option.value == value){
            return (
                <option value={option.value} selected>{option.name}</option>
            )
        }
        return (
            <option value={option.value}>{option.name}</option>
        )
    })
    return (
        <select className={'form-control small select-day'} onChange={(event) => handleChange(event.target.value)}>{options}</select>
    )
}

const SelectHour = ({value, handleChange}) => {
    const options = getHourSlots().map((option, index) => {
        if(option.value == value){
            return (
                <option value={option.value} selected>{option.name}</option>
            )
        }
        return (
            <option value={option.value}>{option.name}</option>
        )
    })
    return (
        <select className={'form-control small select-hour'} onChange={(event) => handleChange(event.target.value)}>{options}</select>
    )
}

const SelectCustom = ({label, value, options, handleChange}) => {
    const getValues = () => {
        return options.map((option, index) => {
            if(option.value == value){
                return (
                    <div key={index} className={'select_custom__item active'} onClick={() => handleChange(option.value)}>{option.name}</div>
                )
            }
            return (
                <div key={index} className={'select_custom__item'} onClick={() => handleChange(option.value)}>{option.name}</div>
            )
        })
    }
    return (
        <div className={'select_custom'}>
            <div className={'select_custom__label'}>
                {label}
            </div>
            <div className={'select_custom__items'}>
                {getValues()}
            </div>
        </div>
    )
}

const SlotLine = ({id, day_number, start, end, handleUpdate, handleDelete}) => {
    return (
        <div className={'opening_days__slot'}>
            <SelectDay value={day_number} handleChange={(value) => handleUpdate(id, 'day_number', value)}/>
            <SelectHour value={start} handleChange={(value) => handleUpdate(id, 'start', value)}/>
            <SelectHour value={end} handleChange={(value) => handleUpdate(id, 'end', value)}/>
            <button onClick={() => handleDelete(id)}><span className={'icon-icon-trash-bin'}></span></button>
        </div>
    )
}

const OpeningDay = () => {


    const [slots, setSlots] = useState([])

    useEffect(() => {
        axios.get('/pro/availability/slots', {}).then((res) => {
            const {data} = res
            setSlots(data)
         }).catch((e) => {
         });
    }, [])

    const handleAdd = () => {
        axios.post('/pro/availability/slots/add', {}).then((res) => {
            const {data} = res
            const slotsClone = [...slots]
            slotsClone.push(data)
            setSlots(slotsClone)
         }).catch((e) => {
         })
    }

    const handleUpdate = (id, field, value) => {
        const slotIndex = _.findIndex(slots, ['id', id])
        if(slotIndex >= 0){
            const slot = slots[slotIndex]
            slot[field] = value
            axios.post('/pro/availability/slots/'+id+'/update', slot).then((res) => {
                const {data} = res
                let slotsClone = [...slots]
                slotsClone[slotIndex] = data
                setSlots(slotsClone)
            }).catch((e) => {
            })
        }
    }

    const handleDelete = (id) => {
        const slotIndex = _.findIndex(slots, ['id', id])
        if(slotIndex >= 0){
            axios.post('/pro/availability/slots/'+id+'/delete', {}).then((res) => {
                const {data} = res;
                let slotsClone = [...slots]
                slotsClone.splice(slotIndex, 1)
                setSlots(slotsClone)
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const getButton = () => {
        return (
            <button className={'btn btn-primary'} onClick={handleAdd}>Ajouter</button>
        )
    }

    const getSlots = () => {
        return slots.map((slot, index) => {
            return (
                <SlotLine key={index} handleUpdate={handleUpdate} handleDelete={handleDelete} {...slot}/>
            )
        })
    }

    return (
        <div className={'opening_days'}>
            {getSlots()}
            {getButton()}
        </div>
    )

}

const formatPrice = (value) => {
    const strPrice = _.toString(value)
    const intPart = strPrice.slice(0, strPrice.length - 2)
    const floatPart = strPrice.slice(-2)
    return intPart + ',' + floatPart + '€' 
}

const getAmount = (value) => {
    return _.round(value, 2) * 100
}
const getFees = (value) => {
    return _.round(value * 0.20, 2) * 100
}

const Price = ({value, handleChange}) => {
    const price = getAmount(value)
    const fees = getFees(value)
    const amount = price + fees
    return (
        <div className={'pricing'}>
            <label>Tarification</label>
            <input name={'price'} className={'form-control'} value={value} onChange={(event) => handleChange(event.target.value)} />
            <div className={'line'}>
                <div className={'label'}>Visio de 45 min</div>
                <div className={'value'}>{formatPrice(price)}</div>
            </div>
            <div className={'line'}>
                <div className={'label'}>Comission de 20%</div>
                <div className={'value'}>{formatPrice(fees)}</div>
            </div>
            <div className={'line'}>
                <div className={'label highlight'}>Coût TTC</div>
                <div className={'value'}>{formatPrice(amount)}</div>
            </div>
        </div>
    )
}

const Setting = () => {

    const [settings, setSettings] = useState({duration: 0, rest: 0, price: 0, timezone: ''})

    useEffect(() => {
        axios.get('/pro/availability/settings', {}).then((res) => {
            const {data} = res
            setSettings(data)
         }).catch((e) => {
         });
    }, [])

    const handleUpdate = (field, value) => {
        console.log('de')
        let settingsClone = {...settings}
        settingsClone[field] = value
        axios.post('/pro/availability/settings', settingsClone).then((res) => {
            const {data} = res
            setSettings(data)
        }).catch((e) => {
            console.log(e)
        })
    }

    return (
        <div className={'availability_settings'}>
            <SelectCustom label={'Définissez la durée de vos visios'} options={getDurations()} value={settings.duration} handleChange={(value) => handleUpdate('duration', value)} />
            <SelectCustom label={'Définissez le temps de pause entre chaque visio'} options={getRests()} value={settings.rest} handleChange={(value) => handleUpdate('rest', value)} />
            <Price value={settings.price} handleChange={(value) => handleUpdate('price', value)} />
        </div>
    )
}

const toUnixTime = (date) => {
    return Math.floor(date.getTime() / 1000)
  }


const SelectDateRange = ({index, id, start, end, handleUpdate, handleDelete}) => {

    const getDateFromUnix = (time) => {
        return new Date(time * 1000)
    }
    const handleStart = (date) => {
        handleUpdate({
            id,
            start: toUnixTime(date),
            end
        })
    }
    const handleEnd = (date) => {
        handleUpdate({
            id,
            start,
            end: toUnixTime(date)
        })
    }
    const getLabel = (label) => {
        if(index == 0){
            return (
                <div className={'select_date_range__label'}>{label}</div>
            )
        }
        return null
    }
    return (
        <div className={['select_date_range',index == 0 ? 'first-node' : ''].join(' ')}>
            <div className={'select_date_range__group'}>
                {getLabel('Début')}
                <DatePicker className={'form-control'} dateFormat="dd/MM/yyyy" selected={getDateFromUnix(start)} onChange={(date) => handleStart(date)} />
            </div>
            <div className={'select_date_range__group'}>
                {getLabel('Fin')}
                <DatePicker className={'form-control'} dateFormat="dd/MM/yyyy" selected={getDateFromUnix(end)} onChange={(date) => handleEnd(date)} />
            </div>
            <div className={'select_date_range__remove'} onClick={() => handleDelete(id)}>
                <span className={'icon-icon-trash-bin'}></span>
            </div>
        </div>
    )
}

const Unavailability = () => {

    const [dates, setDates] = useState([])

    useEffect(() => {
        axios.get('/pro/availability/unavailables', {}).then((res) => {
            const {data} = res
            setDates(data)
         }).catch((e) => {
         })
    }, [])

    const getDates = () => {
        return dates.map((date, index) => {
            return (
                <SelectDateRange {...date} index={index} handleUpdate={handleUpdate} handleDelete={handleDelete} />
            )
        })
    }

    const handleAdd = () => {
        axios.post('/pro/availability/unavailables/add', {}).then((res) => {
            const {data} = res
            const datesClone = [...dates]
            datesClone.push(data)
            setDates(datesClone)
         }).catch((e) => {
         })
    }

    const handleUpdate = ({id, start, end}) => {
        const dateIndex = _.findIndex(dates, ['id', id])
        if(dateIndex >= 0){
            axios.post('/pro/availability/unavailables/'+id+'/update', {start, end}).then((res) => {
                const {data} = res
                let datesClone = [...dates]
                datesClone[dateIndex] = data
                setDates(datesClone)
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const handleDelete = (id) => {
        const dateIndex = _.findIndex(dates, ['id', id])
        if(dateIndex >= 0){
            axios.post('/pro/availability/unavailables/'+id+'/delete', {}).then((res) => {
                const {data} = res;
                let datesClone = [...dates]
                datesClone.splice(dateIndex, 1)
                setDates(datesClone)
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const getButton = () => {
        return (
            <button className={'btn btn-primary'} onClick={handleAdd}>Ajouter</button>
        )
    }

    return (
        <div className={'availability_unavailables'}>
            {getDates()}
            {getButton()}
        </div>
    )
}

const Title = ({title, description}) => {
    return (
        <div className={'row'}>
            <div className={'col-lg-12'}>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    )
}

const Separator = () => {
    return (
        <div className="row">
            <div className="col-lg-9">
                <div className="separator"></div>
            </div>
        </div>
    )
}


const Availability = ({}) => {
    return (
        <div>
            <Title title={'Disponibilités'} description={'Définissez vos créneaux de visio'} />
            <OpeningDay />
            <Separator />
            <Title title={'Indisponibilités'} description={'Indiquez les périodes sur lesquelles vous êtes injouagnable'} />
            <Unavailability />
        </div>
    )
}

class AvailabilityElement extends HTMLElement {
   connectedCallback() {
      render(
         <Availability />, this
      );
   }

   disconnectedCallback() {
      unmountComponentAtNode(this);
   }
}

customElements.define('availability-element', AvailabilityElement);
