import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';

import {DURATIONS, RESTS} from './../constants.js'

const InputCustom = ({label, name, value, handleChange}) => {
    return (
        <div className={'input_custom'}>
            <div className={'input_custom__label'}>
                {label}
            </div>
            <div className={'input_custom__items'}>
                <input type="text" name={name} value={value} onChange={(e) => handleChange(e.target.value)} />
            </div>
        </div>
    )
}

const SelectCustom = ({label, value, options, handleChange}) => {
    const getValues = () => {
        return options.map((option, index) => {
            if(option.value == value){
                return (
                    <div key={index} className={'select_custom__item active'} onClick={() => handleChange(option.value)}>{option.name}</div>
                )
            }
            return (
                <div key={index} className={'select_custom__item'} onClick={() => handleChange(option.value)}>{option.name}</div>
            )
        })
    }
    return (
        <div className={'select_custom'}>
            <div className={'select_custom__label'}>
                {label}
            </div>
            <div className={'select_custom__items'}>
                {getValues()}
            </div>
        </div>
    )
}

const formatPrice = (value) => {
    const strPrice = _.toString(value)
    const intPart = strPrice.slice(0, strPrice.length - 2)
    const floatPart = strPrice.slice(-2)
    return intPart + ',' + floatPart + '€' 
}

const getAmount = (value) => {
    return _.round(value, 2) * 100
}
const getFees = (value) => {
    return _.round(value * 0.20, 2) * 100
}

const PriceCustom = ({value, handleChange}) => {
    const price = getAmount(value)
    const fees = getFees(value)
    const amount = price + fees
    return (
        <div className={'pricing'}>
            <label>Tarification</label>
            <input name={'price'} className={'form-control'} value={value} onChange={(event) => handleChange(event.target.value)} />
            <div className={'line'}>
                <div className={'label'}>Visio de 45 min</div>
                <div className={'value'}>{formatPrice(price)}</div>
            </div>
            <div className={'line'}>
                <div className={'label'}>Comission de 20%</div>
                <div className={'value'}>{formatPrice(fees)}</div>
            </div>
            <div className={'line'}>
                <div className={'label highlight'}>Coût TTC</div>
                <div className={'value'}>{formatPrice(amount)}</div>
            </div>
        </div>
    )
}

const NewProduct = ({product, step, setStep, handleNewProductUpdate, handleNewProductSubmit}) => {
    if(step == 'new'){
        return (
            <div className={'availability_settings'}>
                <InputCustom name={'name'} label={'Description du créneau'} value={product.name} handleChange={(value) => handleNewProductUpdate('name', value)} />
                <SelectCustom name={'duration'} label={'Définissez la durée de vos visios'} options={DURATIONS} value={product.duration} handleChange={(value) => handleNewProductUpdate('duration', value)} />
                <SelectCustom name={'rest'} label={'Définissez le temps de pause entre chaque visio'} options={RESTS} value={product.rest} handleChange={(value) => handleNewProductUpdate('rest', value)} />
                <PriceCustom value={product.price} handleChange={(value) => handleNewProductUpdate('price', value)} />
                <button onClick={() => handleNewProductSubmit()}>Ajouter</button>
            </div>
        )
    }
    return (
        <button onClick={() => setStep('new')}>Ajouter</button>
    )
}

const UpdateProduct = ({product, step, setStep, handleProductUpdate, handleProductSubmit}) => {
    if(step == 'update'){
        return (
            <div className={'availability_settings'}>
                <InputCustom name={'name'} label={'Description du créneau'} value={product.name} handleChange={(value) => handleProductUpdate('name', value)} />
                <SelectCustom name={'duration'} label={'Définissez la durée de vos visios'} options={DURATIONS} value={product.duration} handleChange={(value) => handleProductUpdate('duration', value)} />
                <SelectCustom name={'rest'} label={'Définissez le temps de pause entre chaque visio'} options={RESTS} value={product.rest} handleChange={(value) => handleProductUpdate('rest', value)} />
                <PriceCustom value={product.price} handleChange={(value) => handleProductUpdate('price', value)} />
                <button onClick={() => handleProductSubmit()}>Ajouter</button>
            </div>
        )
    }
    return null
}

const Product = ({data, handleProduct}) => {
    return (
        <div onClick={() => handleProduct(data)}>
            <div>{data.name} | Prix: {data.price} € | Durée: {data.duration}</div>
        </div>
    )
} 

const List = ({products, handleProduct, step}) => {
    if(step == 'list'){
        return products.map((product) => {
            return (
                <Product data={product} handleProduct={handleProduct} />
            )
        })
    }
}

const Products = () => {

    const DEFAULT_PRODUCT = {name: "", duration: 0, rest: 0, price: 0}

    const [products, setProducts] = useState([])
    const [product, setProduct] = useState({...DEFAULT_PRODUCT})
    const [step, setStep] = useState('list')

    useEffect(() => {
        axios.get('/api/pro/products', {}).then((res) => {
            const {data} = res
            setProducts(data)
         }).catch((e) => {
         });
    }, [])

    const handleNewProductEnable = () => {
        setProduct({...DEFAULT_PRODUCT})
        setNewProductEnable(true)
    }

    const handleNewProductUpdate = (field, value) => {
        console.log(field)
        console.log(value)
        let productClone = {...product}
        productClone[field] = value
        setProduct({...productClone})
    }

    const handleNewProductSubmit = () => {
        axios.post('/api/pro/product', product).then((res) => {
            const {data} = res
            const productsClone = [...products]
            productsClone.push(data)
            setProducts(productsClone)
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleProductUpdate = (field, value) => {
        let productClone = {...product}
        productClone[field] = value
        setProduct({...productClone})
    }

    const handleProductSubmit = () => {
        axios.post('/api/pro/product/' + product.id, product).then((res) => {
            const {data} = res
            const productsClone = [...products]
            const findIndex = _.findIndex(productsClone, ['id', product.id])
            if(findIndex >= 0){
                productsClone[findIndex] = product
                setProducts(productsClone)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleProduct = (data) => {
        setProduct(data)
        setStep('update')
    }

    const defaultProps = {
        product,
        products,
        handleProduct,
        step,
        setStep,
        handleNewProductUpdate,
        handleNewProductSubmit,
        handleProductUpdate,
        handleProductSubmit
    }

    return (
        <div className={''}>
            <List {...defaultProps} />
            <NewProduct {...defaultProps} />
            <UpdateProduct {...defaultProps} />
        </div>
    )

}

export default Products